<template>
  <div class="admin-students-list-page">
    <v-card  v-if="pendingStudentApprovalsCount > 0" class="mb-2" color="primary lighten-5">
      <v-list-item>
        <v-list-item-content>
          <v-list-item-title class="text-wrap body-2">
            There are {{pendingStudentApprovalsCount}} pending approvals for registered students
          </v-list-item-title>
        </v-list-item-content>

        <v-list-item-icon>
          <v-btn @click="openPendingStudentApprovalsList" small color="primary">
            View
          </v-btn>
        </v-list-item-icon>
      </v-list-item>
    </v-card>
    <v-row justify="center">
      <v-col md="6">
        <!-- list of all students with search -->
        <intract-smart-list
          ref="admin-students-smart-list"
          class="mt-4"
          paginated
          :endpoint="studentEndpoint"
          :handle-click="handleClick"
          :filter-fields="filterFields"
          :item-options="{
            avatar: 'image',
            handleClick: handleClick,
            appendIcon: 'mdi-arrow-right',
          }"
        >
          <template v-slot:list-item-content="{ item: student }">
            <v-list-item-content>
              <v-list-item-title>{{ student.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                student.room.sections
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                student.username
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </intract-smart-list>
      </v-col>
    </v-row>
    <!-- intract create student sheet -->
    <intract-create-edit-sheet
    persistent

      key="student-create-sheet"
      class="student-create-sheet"
      title="Create Student"
      :visible="createStudentSheet.visible"
      @close="
        createStudentSheet.visible = false;
        createStudentSheet.editId = null;
        createStudentSheet.obj.image = null;
      "
      :fields="studentCreationForm"
      submit-button-text="Create"
      :endpoint="endpoints.studentViewSet"
      :edit-id="createStudentSheet.editId"
      :data-object="createStudentSheet.obj"
      :create-success-message="createStudentSheet.createSuccessMessage"
      @objectCreated="studentCreated"
      @updateObject="(obj) => (createStudentSheet.obj = obj)"
    >
      <template v-slot:custom-field--image="{ obj }">
        <v-col class="text-center">
          <div>
            <v-avatar size="100px" class="mb-2">
              <v-img
                v-if="!obj.image"
                src="../../../assets/images/default.jpg"
              ></v-img>
              <v-img v-else :src="obj.image.document"></v-img
            ></v-avatar>
          </div>
          <v-btn
            class="my-2"
            color="primary"
            @click="$refs.profileImageField.$refs.input.click()"
            ><v-icon class="mr-3">mdi-image</v-icon
            ><span v-if="!obj.image">Add Image</span
            ><span v-else>Edit Image</span></v-btn
          >
          <v-file-input
            v-show="false"
            class="ma-0 pa-0"
            ref="profileImageField"
            @change="(file) => addProfileImage(file, obj)"
            accept="image/*"
          ></v-file-input>
        </v-col>
      </template>
      <template v-slot:custom-field--username="{ obj }">
        <v-text-field
          v-model="obj.username"
          outlined
          :error-messages="usernameError"
          maxlength="30"
          hide-details="auto"
          :rules="usernameRules"
          @input="checkUsernameExists(obj.username)"
        >
          <template #label>
            Username<span class="red--text"><strong> * </strong></span>
          </template>
        </v-text-field>
        <v-col cols="12" v-if="obj.phone || obj.full_name">
          Suggestions:
          <v-chip
            class="mx-1"
            v-if="obj.phone && obj.username != obj.phone"
            @click="
              obj.username = obj.phone;
              checkUsernameExists(obj.username);
            "
            >{{ obj.phone }}</v-chip
          >
          <v-chip
            class="mx-1"
            v-if="
              obj.full_name && obj.username != convertToUsername(obj.full_name)
            "
            @click="
              obj.username = convertToUsername(obj.full_name);
              checkUsernameExists(obj.username);
            "
            >{{ convertToUsername(obj.full_name) }}</v-chip
          >
        </v-col>
      </template>
    </intract-create-edit-sheet>
    <!-- list of disabled student -->
    <intract-list-sheet
      :visible="showDisabledStudents"
      @close="showDisabledStudents = false"
      title="Deactivated Students"
    >
      <template v-slot:list>
        <intract-smart-list
          ref="disabled-students-smart-list"
          paginated
          :endpoint="disabledStudentsEndpoint"
          :item-options="{
            avatar: 'image',
            handleClick: handleClick,
            appendIcon: 'mdi-arrow-right',
          }"
        >
          <template v-slot:list-item-content="{ item: student }">
            <v-list-item-content>
              <v-list-item-title>{{ student.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                student.room.sections
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                student.username
              }}</v-list-item-subtitle>
            </v-list-item-content>
          </template>
        </intract-smart-list>
      </template>
    </intract-list-sheet>

    <!-- list of pending student approvals-->
    <intract-list-sheet
      :visible="showPendingStudentApprovals"
      @close="showPendingStudentApprovals = false"
      title="Pending Approvals"
    >
      <template v-slot:list>
        <intract-smart-list
          ref="pending-student-approvals-smart-list"
          paginated
          :endpoint="pendingStudentApprovalsEndpoint"
          :item-options="{
            avatar: 'image',
            handleClick: handleClick,
            appendIcon: 'mdi-arrow-right',
          }"
          @updateTotalCount="(count) => pendingStudentApprovalsCount = count"
        >
          <template v-slot:list-item-content="{ item: student }">
            <v-list-item-content>
              <v-list-item-title>{{ student.full_name }}</v-list-item-title>
              <v-list-item-subtitle>{{
                student.room.sections
              }}</v-list-item-subtitle>
              <v-list-item-subtitle>{{
                student.username
              }}</v-list-item-subtitle>

            </v-list-item-content>
          </template>
          <template v-slot:list-item-right="{ item: student }">
            <v-list-item-icon class="align-self-top">
              <!-- <v-chip color="green lighten" outlined small> -->
                <small class="secondary--text">{{ moment(student.created).fromNow() }}</small>
              <!-- </v-chip> -->
            </v-list-item-icon>
          </template>
        </intract-smart-list>
      </template>
    </intract-list-sheet>

    <!-- create student fab -->
    <v-fab-transition>
      <v-btn
        v-if="!isLoading && !currentUser.is_student"
        color="primary"
        @click="addStudent"
        dark
        right
        bottom
        fixed
        fab
      >
        <v-icon>mdi-plus</v-icon>
      </v-btn>
    </v-fab-transition>

    <image-cropper-dialog
      ref="cropperDialog"
      :uploadedImage="uploadedImage"
      @onCrop="(croppedImage) => {
        updateObj.image = {
          document: croppedImage,
          file_name: imageName,
        };
      }"
    />
    
  </div>
</template>
<script>
import Mixins from "@utils/mixins";
import IntractSmartList from "@components/generics/IntractSmartList";
import EventBus from "@utils/event_bus";
import IntractCreateEditSheet, {
  CreateEditFieldTypes,
} from "@components/generics/IntractCreateEditSheet.vue";
import ImageCropperDialog from "@components/dialogs/ImageCropperDialog"
import { mapGetters } from "vuex";
import debounce from "debounce";
import IntractListSheet from "@components/generics/IntractListSheet";
import moment from "moment";

export default {
  name: "AdminStudentsList",
  mixins: [Mixins.essentials, Mixins.pullToRefresh, Mixins.handleAppBarOptions],
  components: {
    IntractSmartList,
    IntractListSheet,
    IntractCreateEditSheet,
    ImageCropperDialog,
  },
  data() {
    return {
      moment: moment,
      usernameError: null,
      usernameRules: [
        (v) => !!v || v === 0 || 'Required',
        (v) => /^(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+$/.test(v) || 'Invalid',
        (v) => !v ||v.length >= 5 || 'Should be minimum 5 characters',
      ],
      showDisabledStudents: false,
      showPendingStudentApprovals: false,
      pendingStudentApprovalsCount: 0,
      createStudentSheet: {
        visible: false,
        createSuccessMessage: "Student created successfully!",
        editId: null,
        obj: {
          is_student: true,
          is_faculty: false,
          is_admin: false,
          institution: null,
        },
        adminPanelCounts: {
          total_students: 0,
        }
      },
      uploadedImage: null,
      updateObj: null,
      imageName: null,
    };
  },
  computed: {
    ...mapGetters(["allRooms"]),
    appBarOptions() {
      return {
        actions: [
          {
            id: 1,
            title: "View Deactivated Students",
            icon: "mdi-account-remove",
            onClick: this.openDisabledStudentsList,
          },
          {
            id: 2,
            title: "Import Students",
            icon: "mdi-import",
            onClick: this.goToImportPage,
            hide: !this.currentUser.is_admin
          }
        ],
      };
    },
    studentEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.studentViewSet, [
        "disabled=false",
        "registration_approved=true",
        "ordering=room__first_section__order,room__second_section__order,room__third_section__order,full_name",
      ]);
    },
    disabledStudentsEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.studentViewSet, [
        "disabled=true",
        "registration_approved=true",
        "ordering=room__first_section__order,room__second_section__order,room__third_section__order",
      ]);
    },
    pendingStudentApprovalsEndpoint() {
      return this.Helper.addUrlParams(this.endpoints.studentViewSet, [
        "registration_approved=false",
        "ordering=-created",
      ]);
    },
    studentCreationForm() {
      return {
        image: {
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          // pattern: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        },
        full_name: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Full Name",
          required: true,
          md: 6,
          max: 255,
        },
        room: {
          fieldType: CreateEditFieldTypes.SELECT,
          label: "Classroom",
          md: 6,
          required: true,
          // endpoint: th is.endpoints.classroomViewSet,
          items: this.allRooms,
          itemText: "sections",
          returnObject: true,
        },
        phone: {
          fieldType: CreateEditFieldTypes.TEXTFIELD,
          label: "Phone",
          inputmode: "numeric",
          required: true,
          md: 6,
          max:12, 
          input: "phone",
          customRules: [
            (v) => v == null || v.length >= 10 || "Should be atleast 10 digits",
          ],
          pattern: /^(0|[1-9][0-9]*)$/,
        },
        username: {
          md: 6,
          fieldType: CreateEditFieldTypes.CUSTOMFIELD,
          // pattern: /^(?=.{8,20}$)(?![_.])(?!.*[_.]{2})[a-zA-Z0-9._]+(?<![_.])$/,
        },
      };
    },
    filterFields() {
      return {
        classroom: {
          model: null,
          multiple: true,
          items: this.allRooms,
          label: "Filter by Classroom",
          itemValue: "id",
          itemText: "sections",
          param: "room__in",
        },
      };
    },

  },
  methods: {
    async getAdminPanelCounts() {
      this.adminPanelCounts = await this.api.call(
        this.essentials,
        this.endpoints.adminPanelCounts
      );
    },
    async addStudent(){
      await this.getAdminPanelCounts();
      if(!this.currentUser.is_support_account && this.currentInstitution.preferences && this.currentInstitution.preferences.student_limit && this.currentInstitution.preferences.student_limit <= this.adminPanelCounts.total_students){
        this.showSnackbar({
          text: "You have exceeded the maximum number of students allowed",
          type: "error",
        });
        return 
      }
      else{
        this.createStudentSheet.visible = true
      }
    },
    convertToUsername(name) {
      return name.replace(/ /g, ".").trim().toLowerCase();
    },

    checkUsernameExists: debounce(async function (username) {
      if (!username) {
        this.usernameError = null;
        return;
      }
      var url = this.endpoints.checkUsernameExists + `?username=${username}`;
      if (await this.api.call(this.essentials, url)) {
        this.usernameError = "Username Exists";
      } else {
        this.usernameError = null;
      }
    }, 500),

    async addProfileImage(file, obj) {
      if (!file) return;
      this.uploadedImage = await this.Helper.toBase64(file);
      this.imageName = file.name; this.updateObj = obj;
      this.$refs.cropperDialog.initCropper(file.type);
    },
    async handleClick(student) {
      this.showDisabledStudents = false;
      this.showPendingStudentApprovals = false;

      this.$router.push({
        name: "StudentDetails",
        params: { studentId: student.id },
      });
    },

    async studentCreated(student) {
      this.$router.push({
        name: "StudentDetails",
        params: { studentId: student.id },
      });
    },
    async openDisabledStudentsList() {
      if (this.$refs["disabled-students-smart-list"])
        this.$refs["disabled-students-smart-list"].getItems();
      this.showDisabledStudents = true;
    },

    async openPendingStudentApprovalsList() {
      if (this.$refs["pending-student-approvals-smart-list"])
        this.$refs["pending-student-approvals-smart-list"].getItems();
      this.showPendingStudentApprovals = true;
    },

    async getPendingStudentApprovalsCount() {
      this.pendingStudentApprovals = await this.api.call(
        this.essentials,
        this.Helper.addUrlParams(this.pendingStudentApprovalsEndpoint,'paginate=false'),
      )
      this.pendingStudentApprovalsCount = this.pendingStudentApprovals.length;
    },

    async initListeners() {
      var self = this;
      // removes student item from list if deleted or disabled
      EventBus.$on("student_details__student_deleted", (deletedId) => {
        if (self.$refs["admin-students-smart-list"])
          self.$refs["admin-students-smart-list"].removeItem(deletedId);
        if (self.$refs["disabled-students-smart-list"])
          self.$refs["disabled-students-smart-list"].removeItem(deletedId);
        if (self.$refs["pending-student-approvals-smart-list"])
          self.$refs["pending-student-approvals-smart-list"].removeItem(deletedId);
      });
      // Updates student item 
      EventBus.$on("student_details__student_updated", (student) => {
        if (self.$refs["admin-students-smart-list"])
          self.$refs["admin-students-smart-list"].replaceItem(student.id, student);
        if (self.$refs["disabled-students-smart-list"])
          self.$refs["disabled-students-smart-list"].replaceItem(student.id, student);
        if (self.$refs["pending-student-approvals-smart-list"])
          self.$refs["pending-student-approvals-smart-list"].replaceItem(student.id, student);
      });
      // adds new student to list
      EventBus.$on("student_details__student_added", (student) => {
        if (self.$refs["admin-students-smart-list"])
          self.$refs["admin-students-smart-list"].addItem(student, true);
        if (self.$refs["disabled-students-smart-list"])
          self.$refs["disabled-students-smart-list"].removeItem(student.id);
        if (self.$refs["pending-student-approvals-smart-list"])
          self.$refs["pending-student-approvals-smart-list"].removeItem(student.id);
      });
    },

    async goToImportPage(){
      this.$router.push({
        name: "ImportStudent",
        // params: {src:"app"},
      });
    },

    async onRefresh() {
      this.$refs["admin-students-smart-list"].getItems();
      this.$refs["disabled-students-smart-list"].getItems();
      this.$refs["pending-student-approvals-smart-list"].getItems();
    },
  },

  beforeRouteLeave(to, from, next) {
    if (to.name != "StudentDetails") {
      console.log("destroying");
      // this.$destroy();
      EventBus.$emit("keep_alive__destroy_view", "AdminStudentsList");
    }
    next();
  },
  async created() {
    this.createStudentSheet.obj.institution = this.currentInstitution.id;
    this.initListeners();
    this.getPendingStudentApprovalsCount();
  },
};
</script>